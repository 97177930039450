@import url('https://fonts.googleapis.com/css2?family=Gloock&family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,500;1,600;1,700&display=swap');
@import './assets/scss/bootstrap';

body {
  font-family: 'Poppins', sans-serif;
  background-color: white;
}

a {
  text-decoration: none;
}

.dropdown-caret::after {
  content: "\25bc" !important;
  font-size: 12px;
}

.dropdown-menu {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  border: none;
}

select,
.form-select {
  /* Masquer la flèche par défaut */
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;

  /* Ajouter l'icône de la flèche en tant qu'image de fond fleche-deroulante.png */
  background-position: right center;
  background-repeat: no-repeat;
  padding-right: 30px;
  /* pour ajouter un espace pour l'icône */
}

/* Pour les navigateurs qui ne prennent pas en charge la propriété "appearance" */
select::-ms-expand {
  display: none;
}

.required::after {
  content: "*";
  color: rgba(255, 63, 63, 0.849);
  padding-left: 5px;
}

.input-grouped {

  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid transparent;
  border-radius: 8px;

  input {
    font-size: 14px;
    margin: 0;
  }

  span {
    display: inline-block;
  }
}

.btn-icons {
  display: flex;
  align-items: center;

  .material-icons {
    margin-right: 5px;
  }
}

.bg-primary-light {
  background-color: #006F6F0D;
}

//tableau
td,
th {
  font-size: 14px;
}

.btn:focus,
.btn:active {
  border: none;
}

.btn-success,
.btn-danger {
  color: #fff;

  &:hover {
    color: #fff;
  }
}


p {
  margin-bottom: 0 !important;
}

.btn-actions {
  position: absolute;
  width: max-content;
}

//Top
.topbar {
  .btn {
    border: 1px solid #006F6F80;
    min-width: 200px;
    padding: 10px;
    border-radius: 0;
  }
}

.btn {
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;

  &.active {
    background-color: #ffffff;
    border-color: #001e6f47 !important;
  }

}

fieldset,
.fieldset {
  border: 1px solid #fafafa;
  padding: 15px;
  background: #fafafa;
}

legend {
  font-size: 1.1em !important;
  font-weight: 600;
}

.ngx-pagination {
  margin-bottom: 0 !important;
  padding: 0;

  .disabled {
    color: var(--bs-primary) !important;
  }

  a,
  .disabled,
  .current,
  button {
    padding: 0.375rem 0.75rem !important;
    margin-left: 0;
    margin-right: 0;
  }

  li {

    margin-left: 0;
    margin-right: 0;

    &:first-child {
      border-radius: 5px 0 0 5px;
    }

    &:last-child {
      border-radius: 0 5px 5px 0;
    }

    border: 1px solid #CED4DA;
    color: var(--bs-primary);
  }

  .current {
    background-color: var(--bs-primary) !important;
    border: 1px solid var(--bs-primary);
  }
}

.form-check {

  input,
  label {
    cursor: pointer;
  }
}

.btn-success {
  color: white;
}

.window-pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
}

.bg-lighter {
  background: #eaf1f17a;
}

.title {
  font-size: 22px;
  color: rgba(49, 49, 49, 0.6)
}

.service-details {

  .card {
    ul {
      margin-left: 0;
      padding: 1rem;
    }
  }

  .card,
  .li {
    font-size: 13px;
  }

  .subtitle {
    font-size: 16px;
    line-height: 30px;
    color: var(--bs-primary);
  }

  .v-line {
    margin: auto;
    width: 3px;
    height: 15px;
    display: block;
    background-color: var(--bs-primary);
  }
}

.input-styled {
  position: relative;

  .indicator {
    position: absolute;
    top: 16%;
    right: 3px;
  }

  .form-select,
  .form-control {
    border: 2px solid var(--bs-primary);
    background-color: transparent;
    appearance: none;
    background-image: none;

    &:hover[type="file"] {
      cursor: pointer;
    }

    &.is-invalid {
      border-color: var(--bs-danger) !important;
      background-image: none !important;
      appearance: none !important;

      //Rend le plus dans l'input ou le select rouge
      &+.indicator {
        .bi {
          color: var(--bs-danger) !important;
        }
      }
    }
  }
}


.prev-eye {
  position: relative;
}

.prev-eye app-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}

.prev-eye img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 15, 40, 0.2784313725);
  z-index: 1;
  cursor: pointer;
}

.nav-block-page {
  display: none;

  &.show {
    display: block;
  }
}